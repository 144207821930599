import React from 'react';
import Layout from '../../../components/layout';
import Vacancy from '../../../components/shared-components/vacancy';

const NodeJs = () => {
  return (
    <Layout>
      <Vacancy
        division="Development"
        vacancy="BackEnd (Node.js) Developer"
        workType="Full time"
        // location="Yerevan, Armenia"
        intro={
          <p>
            Our BackEnd team is looking for Node.js Developers. Ideal candidates should be result oriented, open to
            learning new modern technologies and find new solutions.
          </p>
        }
        jobResponsibilities={
          <ul>
            <li className="secondary-text">Write a clean and optimized code</li>
            <li className="secondary-text">Develop new features and improve existing features of the software</li>
            <li className="secondary-text">
              Contribute to team and organizational improvements in process and infrastructure
            </li>
            <li className="secondary-text">Code, test and operate Node.js based services.</li>
          </ul>
        }
        qualifications={
          <ul>
            <li className="secondary-text">
              Strong programming skills in JavaScript with at least 2+ years of work experience in backend development
              using Node.js
            </li>
            <li className="secondary-text">BA in computer science or similar relevant field</li>
            <li className="secondary-text">Professional experience with Git</li>
            <li className="secondary-text">Knowledge of TypeScript, GraphQL, PostgreSQL, MongoDB</li>
            <li className="secondary-text">Applicable knowledge of web libraries and frameworks such as React</li>
            <li className="secondary-text">Knowledge of Docker</li>
            <li className="secondary-text">
              Familiarity with the whole web stack, including protocols and web server optimization techniques
            </li>
            <li className="secondary-text">Strong analytical skills and problem-solving aptitude</li>
            <li className="secondary-text">Attention to detail.</li>
          </ul>
        }
      />
    </Layout>
  );
};

export default NodeJs;
